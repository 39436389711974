// react
import { useEffect } from 'react';
// next
import { useRouter } from 'next/router';
// components
import { Layouts } from 'commons/components';
import { DragAndDropUploaderModule } from 'commons/modules';
import HomeModule from '@commons/modules/HomeModule/HomeModule';
// rsuite
import { Grid } from 'rsuite';
// context open cart
import { useCreateOrder, useProgressBarPriceContext, useRecommendedProducts } from 'context';
// context search
import { usePointOfSale } from 'context';
// context
import { useUser } from 'utils/hooks';
// config flags
import { EX3771, EX4204, EX5539, EX6808 } from 'config/flags';
import { useDragAndDrop } from 'context/dragAndDropContext';
// api EP
import { getRecommendedProductsWithPagination, recommendedProductsJob } from 'api';
// utils
import { isGestorERPFormat, isZettiFormat } from 'utils/pointOfSale';

const index = () => {
    // use router
    const router = useRouter();
    const { posId } = router.query;

    // context create order
    const { resetStore } = useCreateOrder();
    const { pointOfSale } = usePointOfSale();

    // zetti
    const isZetti = isZettiFormat(pointOfSale);
    const isGestorERP = isGestorERPFormat(pointOfSale);

    // context drag and drop
    const { setVisibleDragDrop, visibleDragDrop } = useDragAndDrop();

    // context recommended products
    const {
        loadRecommendedProducts,
        setterFetching,
        recommendedProducts,
        reFetching,
        setReFetching,
        setPagination,
        pagination,
    } = useRecommendedProducts();

    const { resetStates } = useProgressBarPriceContext();

    const { user } = useUser({}); //user

    const EX5943 = user?.EX5943; //FF epic rediseño home
    const EX6708 = user?.EX6708;

    // init
    useEffect(() => {
        if (EX5539) {
            if (EX5943) {
                if (!isZetti && !isGestorERP) {
                    setVisibleDragDrop(false);
                }
            } else {
                setVisibleDragDrop(false);
            }
        } else {
            if (EX5943) {
                if (!isZetti) {
                    setVisibleDragDrop(false);
                }
            } else {
                setVisibleDragDrop(false);
            }
        }
        if (isZetti || isGestorERP) {
            resetStates();
        }
        resetStore();
    }, []);

    // RUN JOB RECOMMENDED PRODUCTS
    useEffect(() => {
        if (EX5943) {
            if (EX3771 && router.pathname.split('/').at(-1) === '[posId]' && !!posId && !isZetti && !isGestorERP) {
                localStorage.removeItem('recommended_products_viewed');
                localStorage.removeItem('drugManufacturerStatusList');
                if (+localStorage.getItem('firstPosLoginFetched') !== +router?.query?.posId) {
                    setterFetching(true);
                    if (EX6808) {
                        getRecommendedProductsWithPagination(posId as string)
                            .then((res) => {
                                setPagination(res?.data?.meta);
                                loadRecommendedProducts(res?.data?.data);
                            })
                            .catch((e) => console.error(e))
                            .finally(() => setterFetching(false));
                    } else {
                        recommendedProductsJob(posId)
                            .then(async () => {
                                if (EX4204) {
                                    const res: any = await getRecommendedProductsWithPagination(posId as string);
                                    setPagination(res?.data?.meta);
                                    loadRecommendedProducts(res?.data?.data);
                                }
                            })
                            .catch((e) => console.error(e))
                            .finally(() => setterFetching(false));
                    }
                }
                localStorage.removeItem('firstPosLoginFetched');
            }
        } else {
            if (EX3771 && router.pathname.split('/').at(-1) === '[posId]' && !!posId && !isZetti) {
                localStorage.removeItem('recommended_products_viewed');
                setterFetching(true);
                if (EX6808) {
                    getRecommendedProductsWithPagination(posId as string)
                        .then((res) => {
                            setPagination(res?.data?.meta);
                            loadRecommendedProducts(res?.data?.data);
                        })
                        .catch((e) => console.error(e))
                        .finally(() => setterFetching(false));
                } else {
                    recommendedProductsJob(posId)
                        .then(async () => {
                            if (EX4204) {
                                const res: any = await getRecommendedProductsWithPagination(posId as string);
                                setPagination(res?.data?.meta);
                                loadRecommendedProducts(res?.data?.data);
                            }
                        })
                        .catch((e) => console.error(e))
                        .finally(() => setterFetching(false));
                }
            }
        }
    }, [posId]);

    // RUN REFETCH RECOMMENDED PRODUCTS
    useEffect(() => {
        if (EX5943) {
            if (
                EX3771 &&
                EX4204 &&
                router.pathname.split('/').at(-1) === '[posId]' &&
                !!posId &&
                !!reFetching &&
                !isZetti &&
                !isGestorERP
            ) {
                if (+localStorage.getItem('firstPosLoginFetched') !== +router?.query?.posId) {
                    getRecommendedProductsWithPagination(posId as string, pagination?.pagination?.current_page + 1)
                        .then((res) => {
                            if (res?.data?.data.length) {
                                loadRecommendedProducts([...recommendedProducts, ...res?.data?.data]);
                            }
                            setPagination(res?.data?.meta);
                        })
                        .catch((e) => console.error(e))
                        .finally(() => setReFetching(false));
                    localStorage.removeItem('firstPosLoginFetched');
                }
            }
        } else {
            if (
                EX3771 &&
                EX4204 &&
                router.pathname.split('/').at(-1) === '[posId]' &&
                !!posId &&
                !!reFetching &&
                !isZetti
            ) {
                getRecommendedProductsWithPagination(posId as string, pagination?.pagination?.current_page + 1)
                    .then((res) => {
                        if (res?.data?.data.length) {
                            loadRecommendedProducts([...recommendedProducts, ...res?.data?.data]);
                        }
                        setPagination(res?.data?.meta);
                    })
                    .catch((e) => console.error(e))
                    .finally(() => setReFetching(false));
            }
        }
    }, [reFetching]);

    const validateERP = EX5539 ? !isZetti && !isGestorERP : !isZetti;

    return (
        <>
            <Layouts>
                <Grid fluid style={EX5943 ? { paddingRight: 0, paddingLeft: 0 } : {}}>
                    {/*  ref = fix input don't scroll with us  */}
                    {EX6708 ? <HomeModule /> : validateERP && <HomeModule />}
                    {EX6708
                        ? validateERP && EX5943 && visibleDragDrop && <DragAndDropUploaderModule />
                        : EX5943 && visibleDragDrop && <DragAndDropUploaderModule />}{' '}
                </Grid>
            </Layouts>
        </>
    );
};

export default index;
